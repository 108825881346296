import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ShowSettingsService {
    showSettings$ = new BehaviorSubject<boolean>(true);

    setBookingDetailsFlag(flag: boolean) {
        this.showSettings$.next(flag);
    }
}
